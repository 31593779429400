import { get, isEmpty, isEqual } from 'lodash';

import {
  MARK_PERSISTOR_FOR_PURGE,
  DISPATCH_USER_CONSENT,
  FETCH_MASTER_DATA,
  FETCH_RESTAURANT_MENU,
  FETCH_RESTAURANT_DATA,
  FETCH_RESTAURANT_LIST,
  FETCH_AVAILABLE_CITIES,
  FIND_GUEST_LOCATION,
  UPDATE_MENU,
  FETCH_MENU_ITEM_CUSTOMIZATIONS,
  FETCH_VIEW_CART,
  PLACE_ORDER,
  FETCH_ORDER_STATUS,
  FETCH_ORDERED_ITEMS,
  ATTEMPT_LOGIN_SUCCESS,
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  CLEAR_SELECTED_TABLE,
  CLEAR_UPDATED_SCAN_CODE,
  DISPATCH_SIGNIN_DATA,
  DISPATCH_AUTH_TOKEN,
  GET_CURRENT_ORDER_COUNT,
  FETCH_GUEST_ORDERS,
  API_ERROR_RESPONSE,
  DISABLED_MENU_ITEMS,
  REQUEST_ASSISTANCE,
  DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
  DISPATCH_VERIFIED_LOCALITIES,
  FETCH_SELECTED_TABLE,
  PREVIOUS_SELECTED_TABLE,
  FETCH_LOCALITIES,
  DISPATCH_RESTAURANT_GROUP,
  DISPATCH_CUSTOMIZATION_LOADER,
  FETCH_RESTAURANT_OFFERS,
  DISPATCH_USER_CART,
  DISPATCH_PARENT_ROUTE,
  FETCH_AVAILABLE_OFFERS,
  DISPATCH_CONTEXTUAL_HOME,
  CART_UPSELL_ITEMS,
  FETCH_GUEST_CASH_CARDS,
  CLEAR_GUEST_CASH_CARDS,
  FETCH_GUEST_CASH_CARD_DETAILS,
  CLEAR_GUEST_CASH_CARD_DETAILS,
  FETCH_GUEST_CASH_CARD_TRANSACTIONS,
  CLEAR_GUEST_CASH_CARD_TRANSACTIONS,
  FETCH_RESTAURANT_CASH_CARD,
  CLEAR_RESTAURANT_CASH_CARD,
  CREATE_CASH_CARD_RECHARGE,
  CLEAR_CASH_CASH_RECHARGE,
  DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION,
  CLEAR_VERIFIED_LOCALITY,
  DISPATCH_PROGRESSIVE_WEB_APP_DATA,
  CLEAR_PROGRESSIVE_WEB_APP_DATA,
  FETCH_BRAND_DEALS,
  CLEAR_BRAND_DEALS,
  FETCH_SUBSCRIBED_DEAL,
  FETCH_BRAND_DEAL_DATA,
  DISPATCH_GUEST_DATA,
  CLEAR_GUEST_DATA,
  UPDATE_DEAL_GUEST,
  DISPATCH_GUEST_VISITED_RESTAURANTS,
  FETCH_PREVIOUS_ORDERS,
  CLEAR_PREVIOUS_ORDERS,
  FETCH_OUTLET_DETAILS,
  GUEST_FEEDBACK,
  GET_BRAND_FEEDBACK_SETUP,
  CLEAR_GUEST_FEEDBACK,
  DISPATCH_IS_REWARDS_CLUB_FLOW,
  CREATE_BRAND_GUEST,
  FETCH_MENU_ITEM_VARIATIONS,
  GET_ORDER_FEEDBACK_SETUP,
  FETCH_ORDER_FEEDBACK,
  GET_ORDER_FEEDBACK_RESPONSE,
  CLEAR_FETCH_ORDER_FEEDBACK,
} from './actionType';
import {
  fetchMasterDataApi,
  fetchRestaurantMenuApi,
  fetchRestaurantDataApi,
  fetchRestaurantOffersApi,
  fetchMenuItemCustomizationsApi,
  fetchViewCartItemsApi,
  fetchCartUpsellItemsApi,
  updateCartUpsellItemApi,
  placeOrderApi,
  getOrderStatusApi,
  fetchOrderedItemsApi,
  attemptLoginApi,
  initiatePaymentApi,
  validatePaymentApi,
  getCurrentOrderCountApi,
  fetchGuestOrdersApi,
  verifyPaymentApi,
  updateProfileApi,
  requestAssistanceApi,
  fetchRestaurantListApi,
  getUserProfileDataApi,
  fetchAvailableCitiesApi,
  findGuestLocationApi,
  findGeoLocationApi,
  verifyLocalitiesApi,
  fetchSelectedTableApi,
  fetchLocalitiesApi,
  getOriginalLinkApi,
  deleteGuestVehicleApi,
  fetchGuestCashCardsApi,
  fetchGuestCashCardDetailsApi,
  fetchGuestCashCardTransactionsApi,
  fetchRestaurantCashCardApi,
  createCashCardRechargeApi,
  validateLocalitiesApi,
  fetchProgressiveWebAppDataApi,
  fetchGuestAddressesApi,
  fetchBrandDealsApi,
  subscribeDealApi,
  fetchDealByIdApi,
  findGuestByPhoneNumberApi,
  sendOtpApi,
  updateDealGuestApi,
  fetchSubscribedGuestDealApi,
  getPreviousOrdersApi,
  fetchOutletDetailsApi,
  createGuestFeedbackApi,
  updateGuestFeedbackApi,
  getBrandFeedbackSetupApi,
  addBrandGuestApi,
  addFudrGuestApi,
  updateRewardGuestProfileApi,
  addGuestAddressApi,
  fetchMenuItemVariationsApi,
  checkExisitingRewardGuestApi,
  fetchOrderFeedbackSetupApi,
  createOrderFeedbackApi,
  fetchfeedbackByIdApi,
  fetchOrderFeedbackByIdApi,
} from '../api/api';
import { getGuestByIdAction } from './authentication';
import { extractWhitelabelSubdomain, isNativeWebView } from '../utils/helpers';
import { apiResponseCodes, dealTypes, storageTypes } from '../constants/globals';
import { getItem, setItem } from '../utils/storage';
import { BRAND_REWARD_GUEST_DETAILS, UPDATE_GUEST_COUPON_FILTER } from './rewards/actionType';
import { DISPATCH_SELECTED_ADDRESS } from '../actions/actionType';
import { dispatchRewardGuestProfileAction, updateRewardGuestProfileAction } from './rewards';
import { authenticationResolutionConfig } from '../containers/Ordering/selectors';

export const markPersistorForPurgeAction = (persistorMarkedForPurge) => async (dispatch) => {
  dispatch({
    type: MARK_PERSISTOR_FOR_PURGE,
    persistorMarkedForPurge,
  });
};

export const dispatchUserConsentAction = (userConsent) => async (dispatch) => {
  dispatch({
    type: DISPATCH_USER_CONSENT,
    userConsent,
  });
};

export const fetchMasterDataAction =
  ({ appName }) =>
  async (dispatch) => {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    fetchMasterDataApi({ appName })
      .then((response) => {
        dispatch({
          type: FETCH_MASTER_DATA,
          masterData: get(response, 'data', {}),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() =>
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        }),
      );
  };

export const fetchRestaurantMenuAction =
  (restaurantAndTableId, includeMenu, excludeError) => async (dispatch) => {
    let restaurantMenuItems = {};
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const subDomain = extractWhitelabelSubdomain();
    const url = `/restaurants/scan/${restaurantAndTableId}?includeMenu=${includeMenu}${
      subDomain ? '&subDomain=' + subDomain : ''
    }`;
    await fetchRestaurantMenuApi(url)
      .then((response) => {
        restaurantMenuItems = get(response, 'data', {});
        const {
          restaurantGroup,
          restaurant,
          selectedTable,
          serviceTypes,
          disabledCategories,
          recommendedSections,
        } = restaurantMenuItems;
        if (!isEmpty(restaurantGroup)) {
          dispatch({
            type: DISPATCH_RESTAURANT_GROUP,
            restaurantGroup,
          });
        }
        dispatch({
          type: FETCH_RESTAURANT_MENU,
          restaurant,
          selectedTable,
          serviceTypes,
          sections: get(restaurantMenuItems, 'sections', []),
          disabledCategories,
          recommendedSections,
        });
        // TODO: Need to revist the same in future
        // invalidate previous cart selection and payment related data
        // dispatch(clearViewCartItems());
      })
      .catch((error) => {
        !excludeError && dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        restaurantMenuItems = get(error, 'response.data', {});
      })
      .finally(() => {
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        });
      });
    return restaurantMenuItems;
  };

export const clearRestaurantGroupDataAction = () => async (dispatch) => {
  dispatch({
    type: DISPATCH_RESTAURANT_GROUP,
    restaurantGroup: {},
  });
};

export const invalidateRestaurantDataAction = () => async (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANT_MENU,
    restaurant: {},
    selectedTable: {},
    serviceTypes: [],
    sections: {},
    disabledCategories: {},
    recommendedSections: [],
    restaurantOffers: [],
  });
};

export const fetchRestaurantDataAction =
  ({ restaurantId, depthParam }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      const restaurant = await fetchRestaurantDataApi({ restaurantId, depthParam })
        .then((restaurantDataResponse) => {
          dispatch({
            type: FETCH_RESTAURANT_DATA,
            restaurant: get(restaurantDataResponse, 'data', {}),
          });
          return restaurantDataResponse;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return restaurant;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const fetchRestaurantOffersAction =
  ({ restaurantOffersReqBody }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      const restaurantOffers = await fetchRestaurantOffersApi({
        restaurantOffersReqBody,
      })
        .then((restaurantOffersResponse) => {
          dispatch({
            type: FETCH_RESTAURANT_OFFERS,
            restaurantOffers: get(restaurantOffersResponse, 'data', {}),
          });
          return restaurantOffersResponse;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return restaurantOffers;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const updateMenuAction = (menu) => async (dispatch) => {
  dispatch({
    type: UPDATE_MENU,
    menu,
  });
};

export const fetchMenuItemVariationsAction = (restaurantId, menuItemId) => async (dispatch) => {
  try {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: true,
    });
    const variationsResponse = await fetchMenuItemVariationsApi({ restaurantId, menuItemId })
      .then((response) => {
        dispatch({
          type: FETCH_MENU_ITEM_VARIATIONS,
          variations: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return variationsResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: false,
    });
  }
};

export const fetchMenuItemCustomizationsAction = (restaurantId, menuItemId) => async (dispatch) => {
  try {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: true,
    });
    const customizationResponse = await fetchMenuItemCustomizationsApi({ restaurantId, menuItemId })
      .then((response) => {
        dispatch({
          type: FETCH_MENU_ITEM_CUSTOMIZATIONS,
          customization: get(response, 'data.menuCustomizationGroups', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return customizationResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: false,
    });
  }
};

export const clearPreviousCustomization = () => async (dispatch) => {
  dispatch({
    type: FETCH_MENU_ITEM_CUSTOMIZATIONS,
    customization: {},
  });
  dispatch({
    type: FETCH_MENU_ITEM_VARIATIONS,
    variations: {},
  });
};

export const clearViewCartItems = () => async (dispatch) => {
  dispatch({
    type: FETCH_VIEW_CART,
    items: {},
  });
};

export const fetchViewCartItemsAction = (cartItems) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const viewCartItemsResponse = await fetchViewCartItemsApi(cartItems)
      .then((response) => {
        dispatch({
          type: FETCH_VIEW_CART,
          items: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        dispatch(
          dispatchDisabledMenuItems(get(errorResponse, 'response.data.data.disabledItems', [])),
        );
        return get(errorResponse, 'response', {});
      });
    return viewCartItemsResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const fetchCartUpsellItemsAction = (cartItems) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const cartUpsellItemsResponse = await fetchCartUpsellItemsApi(cartItems)
      .then((response) => {
        dispatch({
          type: CART_UPSELL_ITEMS,
          cartUpsellItems: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return cartUpsellItemsResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const updateCartUpsellItemAction = (updateCartUpsellItemReqBody) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const updateCartUpsellItems = await updateCartUpsellItemApi(updateCartUpsellItemReqBody)
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return updateCartUpsellItems;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const placeOrderAction = (orderItems) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const placeOrderAPIResponse = await placeOrderApi(orderItems)
      .then((response) => {
        const orderedItems = get(response, 'data', {});
        dispatch({
          type: PLACE_ORDER,
          orderedItems,
        });

        return response;
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        dispatch(dispatchDisabledMenuItems(get(error, 'response.data.data.disabledItems', [])));
        return get(error, 'response', {});
      });

    return placeOrderAPIResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const fetchAvailableOffersAction =
  ({ offersReqBody }) =>
  (dispatch) => {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const cartItemsAPIResponse = fetchViewCartItemsApi(offersReqBody)
      .then((response) => {
        dispatch({
          type: FETCH_AVAILABLE_OFFERS,
          availableOffers: get(response, 'data.offers', []),
        });
        return response;
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        return get(error, 'response', {});
      })
      .finally(() => {
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        });
      });

    return cartItemsAPIResponse;
  };

export const getOrderStatus = (orderId) => async (dispatch) => {
  const orderStatus = await getOrderStatusApi(orderId);
  dispatch({
    type: FETCH_ORDER_STATUS,
    orderStatus,
  });
};

export const fetchOrderedItemsAction =
  ({ orderId, accessToken }) =>
  (dispatch) => {
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      const offeredItems = fetchOrderedItemsApi({ orderId, accessToken })
        .then((offeredItemsResponse) => {
          dispatch({
            type: FETCH_ORDERED_ITEMS,
            orderedItems: get(offeredItemsResponse, 'data', {}),
          });
          return offeredItemsResponse;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return offeredItems;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const clearFetchOrderedItemsAction = () => (dispatch) => {
  dispatch({
    type: FETCH_ORDERED_ITEMS,
    orderedItems: {},
  });
};

export const attemptLoginAction = (loginReqBody, context) => async (dispatch) => {
  let loginData = {};
  const brandId = get(loginReqBody, 'brandId', '');

  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const loginDataResponse = await attemptLoginApi(loginReqBody)
      .then((response) => {
        loginData = get(response, 'data', {});
        localStorage.setItem('GuestLoginData', JSON.stringify(get(loginData, 'guest', {})));
        localStorage.setItem('AuthData', JSON.stringify(get(loginData, 'auth', {})));

        if (isNativeWebView) {
          const data = {
            eventType: { type: 'loginData', data: loginData },
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
        dispatch({
          type: ATTEMPT_LOGIN_SUCCESS,
          loginData: get(loginData, 'guest', {}),
        });
        dispatch({
          type: DISPATCH_AUTH_TOKEN,
          authData: get(loginData, 'auth', {}),
        });
        dispatch(dispatchGuestDataAction(get(loginData, 'guest', {})));
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response.data', {});
      });
    // check if the flow is the outside web ordering
    if (!isEmpty(context) && !isEmpty(loginDataResponse)) {
      if (apiResponseCodes.SUCCESS.includes(get(loginDataResponse, 'status'))) {
        const guestDetails = get(loginDataResponse, 'data.guest', {});
        const phoneNumber = get(guestDetails, 'primaryPhoneNumber', '');
        let updateProfileReqBody = {
          brandId,
          guestId: get(guestDetails, 'id', ''),
          primaryMobileNumber: phoneNumber,
          countryCode: get(guestDetails, 'countryCode', ''),
          isUserConsent: true,
          name: get(guestDetails, 'name', ''),
        };
        switch (context) {
          case authenticationResolutionConfig.basicLoyaltyAuthentication:
            updateProfileReqBody = { ...updateProfileReqBody, rewardJoiningDate: new Date() };
            break;
          default:
            break;
        }
        const params = `brandId=${brandId}&phone=${phoneNumber}`;
        // checking if the brand guest exists for a particular brand
        checkExisitingRewardGuestApi(params)
          .then((response) => {
            if (apiResponseCodes.SUCCESS.includes(get(response, 'status'))) {
              // dispatch brand reward guest details here
              dispatch(dispatchRewardGuestProfileAction(get(response, 'data')));
            }
          })
          .catch((errorResponse) => {
            if (apiResponseCodes.NOT_FOUND.includes(get(errorResponse, 'response.status'))) {
              // create brand guest here
              dispatch(updateRewardGuestProfileAction(updateProfileReqBody));
            }
          });
      }
    }
    return loginDataResponse;
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const updateProfileAction = (updateProfileReqBody, guestId) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const updateProfileData = await updateProfileApi({
      updateProfileReqBody,
      guestId,
    })
      .then((response) => {
        localStorage.setItem('GuestLoginData', JSON.stringify(get(response, 'data', {})));
        dispatch({
          type: ATTEMPT_LOGIN_SUCCESS,
          loginData: get(response, 'data', {}),
        });
        dispatch(dispatchGuestDataAction(get(response, 'data', {})));
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response.data', {});
      });
    return updateProfileData;
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const getUserProfileDataAction = (guestId) => async (dispatch) => {
  let userProfileData = {};
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    userProfileData = await getUserProfileDataApi({ guestId });
    localStorage.setItem('GuestLoginData', JSON.stringify(get(userProfileData, 'data', {})));
    dispatch({
      type: ATTEMPT_LOGIN_SUCCESS,
      loginData: get(userProfileData, 'data', {}),
    });
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const dispatchGuestLoginDataAction = (guestLoginData) => async (dispatch) => {
  localStorage.setItem('GuestLoginData', JSON.stringify(get(guestLoginData, 'guest', {})));
  localStorage.setItem('AuthData', JSON.stringify(get(guestLoginData, 'auth', {})));
  dispatch({
    type: ATTEMPT_LOGIN_SUCCESS,
    loginData: get(guestLoginData, 'guest', {}),
  });
  dispatch({
    type: DISPATCH_AUTH_TOKEN,
    authData: get(guestLoginData, 'auth', {}),
  });
};

export const dispatchLogoutAction = () => (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    localStorage.setItem('GuestLoginData', JSON.stringify({}));
    localStorage.setItem('AuthData', JSON.stringify({}));
    dispatch({
      type: ATTEMPT_LOGIN_SUCCESS,
      loginData: {},
    });
    dispatch({
      type: DISPATCH_AUTH_TOKEN,
      authData: {},
    });
    dispatch({
      type: DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
      requestedAssistancesTimeStampArr: [],
    });
    dispatch({
      type: FETCH_GUEST_ORDERS,
      guestOrders: {},
    });
    dispatch({
      type: BRAND_REWARD_GUEST_DETAILS,
      rewardGuestDetails: {},
    });
    // Clear guest cash card list
    dispatch({
      type: CLEAR_GUEST_CASH_CARDS,
    });
    dispatch({ type: CLEAR_VERIFIED_LOCALITY });
    dispatch(clearGuestDataAction());
    // Clear visited outlet's cash card list
    dispatch(clearRestaurantCashCardAction());
    // Clear the guest's previous orders list
    dispatch(clearGuestPreviousOrdersAction());
    //clear the guest feedback
    dispatch(clearGuestFeedbackAction());
    //clear the reward history
    dispatch({ type: UPDATE_GUEST_COUPON_FILTER, filter: {} });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const dispatchGuestDataAction = (guestData) => async (dispatch) => {
  dispatch({
    type: DISPATCH_GUEST_DATA,
    guestData,
  });
};

export const clearGuestDataAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_DATA,
  });
};

export const initiatePaymentAction =
  ({ initiatePaymentReqBody, defaultGateway }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });

      const initiatePaymentresponse = await initiatePaymentApi({
        initiatePaymentReqBody,
        defaultGateway,
      })
        .then((response) => {
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return initiatePaymentresponse;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const validatePaymentAction = (paymentresponse, defaultGateway) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const validatePaymentResponse = await validatePaymentApi({
      paymentresponse,
      defaultGateway,
    })
      .then((response) => {
        const validatePaymentResponseData = get(response, 'data', {});
        const orderedItems = get(validatePaymentResponseData, 'guestOrderDto', {});

        !isEmpty(orderedItems) &&
          dispatch({
            type: FETCH_ORDERED_ITEMS,
            orderedItems,
          });

        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });

    return validatePaymentResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const verifyPaymentAction =
  ({ verifyPaymentReqBody, defaultGateway }) =>
  async (dispatch) => {
    try {
      // dispatch({
      //   type: BEGIN_AJAX_CALL,
      //   showSpinner: true,
      // });
      let orderedItems = {};
      let verifyPaymentResponse = await verifyPaymentApi({
        verifyPaymentReqBody,
        defaultGateway,
      })
        .then((response) => {
          verifyPaymentResponse = get(response, 'data', {});
          orderedItems = get(verifyPaymentResponse, 'guestOrderDto', {});
          dispatch({
            type: FETCH_ORDERED_ITEMS,
            orderedItems,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return verifyPaymentResponse;
    } catch (err) {
      console.log(err);
    } finally {
      // dispatch({
      //   type: END_AJAX_CALL,
      //   showSpinner: false,
      // });
    }
  };

export const beginAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
};

export const endAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const clearSelectedTable = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_TABLE,
    selectedTable: {},
  });
};

export const clearUpdatedScanCode = () => async (dispatch) => {
  dispatch({
    type: CLEAR_UPDATED_SCAN_CODE,
    updatedScanCode: '',
  });
};

export const dispatchSignInDataAction = (signIn) => async (dispatch) => {
  dispatch({
    type: DISPATCH_SIGNIN_DATA,
    signIn,
  });
};

export const dispatchParentRouteAction = (parentRoute) => async (dispatch) => {
  dispatch({
    type: DISPATCH_PARENT_ROUTE,
    parentRoute,
  });
};

export const getCurrentOrderCountAction =
  ({ guestId, restaurantId }) =>
  async (dispatch) => {
    let orderCount = {};
    try {
      orderCount = await getCurrentOrderCountApi({ guestId, restaurantId });
      dispatch({
        type: GET_CURRENT_ORDER_COUNT,
        orderCount: get(orderCount, 'data.orderCount', 0),
      });
    } catch (err) {}
  };

export const fetchGuestOrdersAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());

      const guestOrdersResponse = await fetchGuestOrdersApi({ url })
        .then((response) => {
          const guestOrders = get(response, 'data', {});
          dispatch({
            type: FETCH_GUEST_ORDERS,
            guestOrders,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return guestOrdersResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const apiErrorResponseAction = (errorResponse) => async (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse,
  });
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const clearApiErrorResponseAction = () => async (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse: {},
  });
};

export const dispatchDisabledMenuItems = (disabledItems) => async (dispatch) => {
  dispatch({
    type: DISABLED_MENU_ITEMS,
    disabledItems,
  });
};

export const clearDisabledMenuItems = () => async (dispatch) => {
  dispatch({
    type: DISABLED_MENU_ITEMS,
    disabledItems: [],
  });
};

export const requestAssistanceAction =
  ({ requestAssistanceReqBody, requestedAssistancesTimeStampMap }) =>
  async (dispatch) => {
    let assistanceData = {};
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      assistanceData = await requestAssistanceApi({ requestAssistanceReqBody });
      dispatch({
        type: REQUEST_ASSISTANCE,
        assistanceData: get(assistanceData, 'data', {}),
        isAssistanceUpdated: true,
      });
      dispatch({
        type: DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
        requestedAssistancesTimeStampMap,
      });
    } catch (error) {
      dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const updateAssistanceStatusAction = () => async (dispatch) => {
  dispatch({
    type: REQUEST_ASSISTANCE,
    assistanceData: {},
    isAssistanceUpdated: false,
  });
};

export const clearRequestedTimeStampAction = () => async (dispatch) => {
  dispatch({
    type: DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
    requestedAssistancesTimeStampMap: {},
  });
};

export const fetchRestaurantListAction =
  ({ fetchRestaurantListReqBody }) =>
  async (dispatch) => {
    let restaurantList = {};
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      restaurantList = await fetchRestaurantListApi({
        fetchRestaurantListReqBody,
      });
      dispatch({
        type: FETCH_RESTAURANT_LIST,
        restaurantList: get(restaurantList, 'data', {}),
      });
    } catch (error) {
      dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const fetchAvailableCitiesAction = () => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const availableCitiesResponse = await fetchAvailableCitiesApi()
      .then((response) => {
        dispatch({
          type: FETCH_AVAILABLE_CITIES,
          availableCities: get(response, 'data.cities', []),
        });

        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });

    return availableCitiesResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const fetchLocalitiesAction =
  ({ restaurantCityId }) =>
  async (dispatch) => {
    let localities = [];
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      localities = await fetchLocalitiesApi({ restaurantCityId });
      dispatch({
        type: FETCH_LOCALITIES,
        localities: get(localities, 'data.localities', []),
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const findGuestLocationAction =
  ({ guestId }) =>
  async (dispatch) => {
    try {
      const locationResponse = await findGuestLocationApi({ guestId })
        .then((response) => {
          let guestLocation = get(response, 'data', {});
          guestLocation = { ...guestLocation, timeStamp: Date.now() };
          setItem(storageTypes.localStorage, 'guestLocation', guestLocation); // eslint-disable-line
          dispatch({
            type: FIND_GUEST_LOCATION,
            guestLocation,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return locationResponse;
    } catch (error) {
      console.log(error);
    }
  };

export const dispatchGuestLocationAction = (cityName) => async (dispatch) => {
  dispatch({
    type: FIND_GUEST_LOCATION,
    guestLocation: { cityName },
  });
};

export const findGeoLocationAction = () => async (dispatch) => {
  let guestLocation = {};
  findGeoLocationApi()
    .then((response) => {
      guestLocation = get(response, 'location', {});
      setItem(storageTypes.localStorage, 'geoLocation', guestLocation);
      dispatch(findGuestLocationAction());
    })
    .catch((error) => {
      console.log(error);
    });
};

export const verifyLocalitiesAction =
  ({ verifyLocalitiesReqBody }) =>
  () =>
    verifyLocalitiesApi({ verifyLocalitiesReqBody });

export const dispatchVerifiedLocalitiesAction = (verifiedLocalities) => async (dispatch) => {
  dispatch({
    type: DISPATCH_VERIFIED_LOCALITIES,
    verifiedLocalities,
  });
};

export const fetchSelectedTableAction = (locationId) => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
  let selectedTable = await fetchSelectedTableApi(locationId);
  dispatch({
    type: FETCH_SELECTED_TABLE,
    selectedTable,
  });
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const dispatchPreviousSelectedTableAction = (selectedTable) => async (dispatch) => {
  dispatch({
    type: PREVIOUS_SELECTED_TABLE,
    previousSelectedTable: selectedTable,
  });
};

export const dispatchSelectedAddressAction = (selectedAddress) => async (dispatch) => {
  dispatch({
    type: DISPATCH_SELECTED_ADDRESS,
    selectedAddress,
  });
};

export const getOriginalLinkAction =
  ({ customLinkKey }) =>
  async (dispatch) => {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const originalLinkResponse = await getOriginalLinkApi({ customLinkKey });
    return originalLinkResponse;
  };

export const dispatchUserCartAction = (cart) => (dispatch) => {
  dispatch({
    type: DISPATCH_USER_CART,
    cart,
  });
};

export const deleteGuestVehicleAction = (deleteGuestVehicleReqBody, guestId) => (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
  deleteGuestVehicleApi(deleteGuestVehicleReqBody)
    .then((response) => {
      if (isEqual(response.status, 204)) {
        dispatch(getGuestByIdAction({ guestId }));
      }
    })
    .finally(() => {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    });
};

// Action to set contextual home state into the redux store
export const dispatchContextualHome = (contextualHome) => (dispatch) => {
  dispatch({
    type: DISPATCH_CONTEXTUAL_HOME,
    contextualHome,
  });
};

export const fetchGuestCashCardsAction =
  ({ url }) =>
  async (dispatch) => {
    let guestCashCardsResponse = {};
    try {
      dispatch(beginAjaxCallAction());

      guestCashCardsResponse = await fetchGuestCashCardsApi({ url })
        .then((response) => {
          const guestCashCards = get(response, 'data', {});

          dispatch({
            type: FETCH_GUEST_CASH_CARDS,
            guestCashCards,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return guestCashCardsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const fetchGuestCashCardDetailsAction =
  ({ guestCashCardId }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const cardDetailsResponse = await fetchGuestCashCardDetailsApi({ guestCashCardId })
        .then((response) => {
          dispatch({
            type: FETCH_GUEST_CASH_CARD_DETAILS,
            guestCashCardDetails: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return cardDetailsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const clearGuestCashCardDetailsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_CASH_CARD_DETAILS,
  });
};

export const fetchGuestCashCardTransactionsAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const guestCashCardTransactionsResponse = await fetchGuestCashCardTransactionsApi({
        url,
      })
        .then((response) => {
          dispatch({
            type: FETCH_GUEST_CASH_CARD_TRANSACTIONS,
            guestCashCardTransactions: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return guestCashCardTransactionsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const clearGuestCashCardTransactionsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_CASH_CARD_TRANSACTIONS,
  });
};

export const fetchRestaurantCashCardAction =
  ({ restaurantId }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const restaurantCashCardResponse = await fetchRestaurantCashCardApi({ restaurantId })
        .then((response) => {
          dispatch({
            type: FETCH_RESTAURANT_CASH_CARD,
            restaurantCashCard: get(response, 'data', []),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return restaurantCashCardResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const clearRestaurantCashCardAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_RESTAURANT_CASH_CARD,
  });
};

export const createCashCardRechargeAction =
  (accessToken, rechargeRequestBody) => async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());

      const createCashCardRechargeResponse = await createCashCardRechargeApi(
        accessToken,
        rechargeRequestBody,
      )
        .then((response) => {
          dispatch({
            type: CREATE_CASH_CARD_RECHARGE,
            guestCashCardRechargeDetails: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return createCashCardRechargeResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const clearCashCardRechargeAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_CASH_CASH_RECHARGE,
  });
};

export const dispatchLastCashCardRechargeTransactionAction =
  (rechargeTransaction) => async (dispatch) => {
    dispatch({
      type: DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION,
      lastCashCardRechargeTransaction: rechargeTransaction,
    });
  };

export const validateLocalitiesAction = (validateLocalitiesReqBody) => async (dispatch) => {
  try {
    const validateLocalitiesResponse = await validateLocalitiesApi(validateLocalitiesReqBody)
      .then((response) => response)
      .catch((errorResponse) => get(errorResponse, 'response', {}));

    return validateLocalitiesResponse;
  } catch (err) {
    console.error(err);
  }
};

export const dispatchProgressiveWebAppDataAction =
  ({ progressiveWebAppData }) =>
  async (dispatch) => {
    dispatch({
      type: DISPATCH_PROGRESSIVE_WEB_APP_DATA,
      progressiveWebApp: progressiveWebAppData,
    });
  };

export const clearProgressiveWebAppDataAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROGRESSIVE_WEB_APP_DATA,
  });
};

// engage specific actions

export const fetchBrandDealsAction =
  ({ url, fetchDealsWithoutDispatch }, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const brandDealsResponse = await fetchBrandDealsApi({ url })
        .then((response) => {
          if (!fetchDealsWithoutDispatch) {
            const brandDeals = get(response, 'data', {});
            dispatch({
              type: FETCH_BRAND_DEALS,
              brandDeals,
            });
          }
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return brandDealsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const clearBrandDealsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_BRAND_DEALS,
  });
};

export const subscribeDealAction =
  ({ subscribeDealReqBody }, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const subscribeDealResponse = await subscribeDealApi({ subscribeDealReqBody })
        .then((response) => {
          const subscribedDeal = get(response, 'data', {});
          dispatch({
            type: FETCH_SUBSCRIBED_DEAL,
            subscribedDeal,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return subscribeDealResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchSubscribedGuestDealAction =
  ({ couponCode }, withLoader = true) =>
  async (dispatch) => {
    try {
      console.log(withLoader);
      withLoader && dispatch(beginAjaxCallAction());
      let subscribedDealResponse = {};
      let dealDetailResponse = {};
      subscribedDealResponse = await fetchSubscribedGuestDealApi({ couponCode });
      subscribedDealResponse = subscribedDealResponse.data;
      if (isEqual(subscribedDealResponse.dealType, dealTypes.streak)) {
        dealDetailResponse = await fetchDealByIdApi({
          dealCode: subscribedDealResponse.dealId,
          guestId: subscribedDealResponse.guestId,
        });
        dealDetailResponse = dealDetailResponse.data;
      }
      const subscribedGuestDealResponse = {
        ...dealDetailResponse,
        ...subscribedDealResponse,
      };
      dispatch({
        type: FETCH_SUBSCRIBED_DEAL,
        subscribedDeal: subscribedGuestDealResponse,
      });
      return subscribedGuestDealResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const clearSubscribedGuestDealAction = () => async (dispatch) => {
  dispatch({
    type: FETCH_SUBSCRIBED_DEAL,
    subscribedDeal: {},
  });
};

export const fetchDealByIdAction =
  ({ dealCode, guestId }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const brandDealResponse = await fetchDealByIdApi({ dealCode, guestId })
        .then((response) => {
          const brandDeal = get(response, 'data', {});
          dispatch({
            type: FETCH_BRAND_DEAL_DATA,
            brandDeal,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return brandDealResponse;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const clearBrandDealDataAction = () => async (dispatch) => {
  dispatch({
    type: FETCH_BRAND_DEAL_DATA,
  });
};

export const findGuestByPhoneNumberAction = (phoneNumber) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const findGuestByPhoneNumberAPIResponse = await findGuestByPhoneNumberApi({
      phoneNumber,
    })
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        // dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return findGuestByPhoneNumberAPIResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const sendOtpAction = (phoneNumber, countryCode, res) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const sendOtpResponse = await sendOtpApi(phoneNumber, countryCode, res)
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return sendOtpResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const updateDealGuestAction =
  ({ dealGuestId, updateDealGuestReqBody }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const updateDealGuestResponse = await updateDealGuestApi({
        dealGuestId,
        updateDealGuestReqBody,
      })
        .then((response) => {
          const dealGuestData = get(response, 'data', {});
          dispatch({
            type: UPDATE_DEAL_GUEST,
            subscribedDeal: dealGuestData,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return updateDealGuestResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const fetchGuestPreviousOrdersAction = (previousOrderRequestBody) => async (dispatch) => {
  try {
    const guestPreviousOrders = await getPreviousOrdersApi(previousOrderRequestBody)
      .then((response) => {
        dispatch({
          type: FETCH_PREVIOUS_ORDERS,
          previousOrders: get(response, 'data', []),
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return guestPreviousOrders;
  } catch (error) {
    console.log(error);
  }
};

export const clearGuestPreviousOrdersAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PREVIOUS_ORDERS,
  });
};

export const dispatchVisitedRestaurantsAction = (guestVisitedRestaurants) => async (dispatch) => {
  dispatch({
    type: DISPATCH_GUEST_VISITED_RESTAURANTS,
    guestVisitedRestaurants,
  });
};

export const fetchOutletDetailsAction =
  ({ url }, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const outletDetailsResponse = await fetchOutletDetailsApi({ url })
        .then((response) => {
          const outletDetails = get(response, 'data', {});
          dispatch({
            type: FETCH_OUTLET_DETAILS,
            outletDetails,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return outletDetailsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const createGuestFeedbackAction = (requestBody) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const createGuestFeedbackResponse = await createGuestFeedbackApi(requestBody)
      .then((response) => {
        const guestFeedback = get(response, 'data', {});
        dispatch({
          type: GUEST_FEEDBACK,
          guestFeedback,
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return createGuestFeedbackResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const createOrderFeedbackAction = (requestBody) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const createOrderFeedbackResponse = await createOrderFeedbackApi(requestBody)
      .then((response) => {
        const orderFeedbackResponse = get(response, 'data', {});
        dispatch({
          type: GET_ORDER_FEEDBACK_RESPONSE,
          orderFeedbackResponse,
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return createOrderFeedbackResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const updateGuestFeedbackAction = (requestBody) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const updateGuestFeedbackResponse = await updateGuestFeedbackApi(requestBody)
      .then((response) => {
        const updatedGuestFeedback = get(response, 'data', {});
        dispatch({
          type: GUEST_FEEDBACK,
          guestFeedback: updatedGuestFeedback,
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return updateGuestFeedbackResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const fetchOrderFeedbackSetupAction = (requestBody) => async (dispatch) => {
  try {
    const brandFeedbackSetupResponse = await fetchOrderFeedbackSetupApi(requestBody)
      .then((response) => {
        const feedbackSetup = get(response, 'data', {});
        dispatch({
          type: GET_ORDER_FEEDBACK_SETUP,
          feedbackSetup,
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return brandFeedbackSetupResponse;
  } catch (err) {
    console.error(err);
  } finally {
  }
};

export const fetchfeedbackByIdAction = (requestBody) => async (dispatch) => {
  try {
    const orderFeedbackResponse = await fetchOrderFeedbackByIdApi(requestBody)
      .then((response) => {
        const feedbackObj = get(response, 'data', {});
        dispatch({
          type: FETCH_ORDER_FEEDBACK,
          feedbackObj,
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return orderFeedbackResponse;
  } catch (err) {
    console.error(err);
  } finally {
  }
};

export const clearFetchfeedbackByIdAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FETCH_ORDER_FEEDBACK,
  });
};

export const getBrandFeedbackSetupAction = (requestBody) => async (dispatch) => {
  try {
    const brandFeedbackSetupResponse = await getBrandFeedbackSetupApi(requestBody)
      .then((response) => {
        const feedbackSetup = get(response, 'data', {});
        dispatch({
          type: GET_BRAND_FEEDBACK_SETUP,
          feedbackSetup,
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return brandFeedbackSetupResponse;
  } catch (err) {
    console.error(err);
  } finally {
  }
};

export const clearGuestFeedbackAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_FEEDBACK,
  });
};

export const addFudrGuestAction =
  ({ signupReqBody }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const addFudrGuestAPIResponse = await addFudrGuestApi({
        signupReqBody,
      })
        .then((response) => {
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return addFudrGuestAPIResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const addBrandGuestAction =
  ({ addBrandGuestReqBody }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const addBrandGuestAPIResponse = await addBrandGuestApi({
        addBrandGuestReqBody,
      })
        .then((response) => {
          dispatch({
            type: CREATE_BRAND_GUEST,
            brandGuest: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return addBrandGuestAPIResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const dispatchIsRewardsClubFlowAction = (flag) => async (dispatch) => {
  dispatch({
    type: DISPATCH_IS_REWARDS_CLUB_FLOW,
    isRewardsClubFlow: flag,
  });
};
